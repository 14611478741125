import { useStore } from 'vuex';

export function isRoundMissingResults(round) {
  return !!round.pairings.find(pairing => pairing.result == 'pending')
}

export function computeParticipantPaired(tournamentRounds, participantId) {
  return !!tournamentRounds.find((round) => {
    return round.pairings.find((pairing) => {
      return (pairing.white_player_id == participantId ||
        pairing.black_player_id == participantId) &&
        pairing.result != "zero_bye" &&
        pairing.result != "half_bye" &&
        pairing.result != "full_bye"
    });
  });
}

export function hasUserActivationRights(tournament) {
  const store = useStore();

  return (store.getters.getUserReferee && store.getters.getUserReferee.activation_rights) || hasUserPlayerRights(tournament)
}

export function hasUserPlayerRights(tournament) {
  const store = useStore();

  return isUserAdmin(tournament) ||
    (store.getters.getUserReferee && store.getters.getUserReferee.player_rights)
}

export function hasUserRoundRights(tournament) {
  const store = useStore();

  return isUserAdmin(tournament) ||
    (store.getters.getUserReferee && store.getters.getUserReferee.round_rights)
}

export function isUserAdmin(tournament) {
  const store = useStore();

  return tournament.admin?.id === store.getters.getUser?.id;
}

export function isUserReferee(tournament) {
  const store = useStore();

  return isUserAdmin(tournament) || !!store.getters.getUserReferee
}

export function checkParticipantLicence(tournament, participant) {
  if (tournament.federation === "ffe") {
    if (tournament.format === "standard" && participant.licence === "A"
      || (tournament.format === "rapid" || tournament.format === "blitz") && (participant.licence === "A" || participant.licence === "B")
    ) {
      return true

    // Default value for ffe tournament
    } else {
      return false
    }

  // Default value for non ffe tournament
  } else {
    return true
  }
}

export function handleFileDownload(response, fileName) {
    // Create a Blob object from the binary data received
    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    // Create a temporary URL for the Blob object
    const url = window.URL.createObjectURL(blob);

    // Create a link element and set its href attribute to the temporary URL
    const link = document.createElement('a');
    link.href = url;

    // Set the filename for the downloaded file (optional)
    link.setAttribute('download', fileName);

    // Append the link element to the document body
    document.body.appendChild(link);

    // Trigger the click event on the link element to start the download
    link.click();

    // Cleanup: remove the link and revoke the temporary URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}

export function elo(participant, tournament_format) {
  if (tournament_format === "blitz") {
    return participant.elo_blitz;
  } else if (tournament_format === "rapid") {
    return participant.elo_rapid;
  } else {
    return participant.elo_standard;
  }
}

export async function registerServiceWorker() {
  if (navigator.serviceWorker) {
    try {
      // Register the service worker
      navigator.serviceWorker.register('/service_worker.js');

      // Wait until the service worker is ready
      const serviceWorkerRegistration = await navigator.serviceWorker.ready;
      return serviceWorkerRegistration;

    } catch (error) {
      console.error('Service worker registration failed:', error);
    }
  } else {
    console.error('This browser does not support service workers');
  }
}

export async function subscribePushManager(serviceWorkerRegistration) {
  if (!('Notification' in window) || 'Notification' in window && Notification.permission === "granted") {
    try {
      // Subscribe to push notifications
      const subscription = await serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: vapidPublicKey
      });
      return subscription;

    } catch (error) {
      console.error('Push subscription failed:', error);
    }
  } else {
    console.warn('Notification permission is not granted.');
  }
}

export async function initServiceWorker() {
  let serviceWorkerRegistration = await navigator.serviceWorker?.getRegistration('/service_worker.js')

  if ('Notification' in window && !serviceWorkerRegistration) {
    serviceWorkerRegistration = await registerServiceWorker();
  }

  let webpushSubscription = null
  if ('Notification' in window && Notification.permission === "granted") {
    webpushSubscription = await serviceWorkerRegistration.pushManager.getSubscription();
    if (!webpushSubscription) {
      webpushSubscription = await subscribePushManager(serviceWorkerRegistration)
    }
  }

  return { serviceWorker: serviceWorkerRegistration, webpushSubscription: webpushSubscription }
}

export function seconds2scale(secondsValue) {
  if (secondsValue < 60) {
    return { value: secondsValue, scale: "s" }
  } else if (secondsValue < 3600) {
    return { value: secondsValue /= 60, scale: "m" }
  } else if (secondsValue < 86400) {
    return { value: secondsValue /= 3600, scale: "h" }
  } else {
    return { value: secondsValue /= 86400, scale: "d" }
  }
}

export function scale2seconds(timeValue, timeScale) {
  if (!Number.isFinite(timeValue))
    return timeValue

  return timeScale === "m" ? timeValue * 60 : timeScale === "h" ? timeValue * 3600 : timeScale === "d" ? timeValue * 86400 : timeValue;
}
